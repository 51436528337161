




























































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        counter: null,
        isCompanionRequired: false,
        isRegistrationCodeRequired: false,
        registrationFeeCodesData: {
          type: "single use",
          codes: [],
        },
        registrationCode: null,
        additionalField: Boolean,
        additionalServicesIds: [],
      }),
    },
    services: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    MGenerateDiscountCode: () =>
      import("@/components/molecules/m-generate-discount-code.vue"),
  },

  setup(props, { root }) {
    const state = reactive({
      counter: false,
      isCompanionEnabled: false,
      codeGenerator: false,
      singleCode: "",
      singleCodeValue: "",
    });

    const selectAll = () => {
      if (
        props.value.additionalServicesIds &&
        props.value.additionalServicesIds.length
      ) {
        props.value.additionalServicesIds = [];
        props.services.forEach((el: any) => {
          if (el.groupIsEnabled && el.isEnabled) {
            props.value.additionalServicesIds.push(el.id);
          }
        });
      } else {
        props.services.forEach((el: any) => {
          if (el.groupIsEnabled && el.isEnabled) {
            props.value.additionalServicesIds.push(el.id);
          }
        });
      }
    };

    const deselectAll = () => {
      props.value.additionalServicesIds = [];
    };

    watch(
      () => state.counter,
      () => {
        if (!state.counter) {
          props.value.counter = null;
        }
      }
    );
    watch(
      () => props.value.isRegistrationCodeRequired,
      () => {
        if (props.value.isRegistrationCodeRequired === false) {
          state.singleCodeValue = "";
          props.value.registrationFeeCodesData.codes = [];
        }
      }
    );
    watch(
      () => props.value.registrationFeeCodesData.type,
      () => {
        state.singleCodeValue = "";
        props.value.registrationFeeCodesData.codes = [];
      }
    );
    watch(
      () => state.singleCodeValue,
      () => {
        if (state.singleCodeValue.length > 1)
          props.value.registrationFeeCodesData.codes = [state.singleCodeValue];
      }
    );

    const getCodes = (value: string[]) => {
      props.value.registrationFeeCodesData.codes = value.map((e) => e);
    };

    const removeCode = (index: number) => {
      props.value.registrationFeeCodesData.codes.splice(index, 1);
    };

    const copySingleCode = (index: number) => {
      navigator.clipboard.writeText(
        props.value.registrationFeeCodesData.codes[index]
      );
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("layout.misc.codeCopied"),
      });
    };

    const addSingleCode = () => {
      props.value.registrationFeeCodesData.codes.push(state.singleCode);
      state.singleCode = "";
    };

    const fetchModuleConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-module`)
        .then(({ data }) => {
          state.isCompanionEnabled = data.isCompanionEnabled;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchModuleConfig);

    const copyAllCodes = (discountCodes: any) => {
      const codes = discountCodes.map((item: string) => item).join(", ");
      navigator.clipboard.writeText(codes);
      root?.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("event.panel.config.discounts.add.allCodesCopied"),
      });
    };

    return {
      state,
      selectAll,
      deselectAll,
      getCodes,
      removeCode,
      copySingleCode,
      addSingleCode,
      copyAllCodes,
    };
  },
});
